@tailwind base;
@tailwind components;
@tailwind utilities;
@layer utilities {

    .clip-your-needful-style {
 
        clip-path: polygon(0 0, 100% 0, 100% 75%, 0 98%);
 
    }
 
 }

 .Mui-completed{
    color: #FF9900;
 }

 .MuiStepConnector-active{
    background-color: #FF9900;
 }

 .css-yrdy0g-MuiDataGrid-columnHeaderRow{
    background-color: #FF9900;
    font-size: larger;
    font-weight: 800;
 }

.css-ypiqx9-MuiDialogContent-root{
   padding: 0 20px 10px 24px;
 }